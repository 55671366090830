import service from '@/utils/request'; // @Tags PopularizeMember
// @Summary 创建PopularizeMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PopularizeMember true "创建PopularizeMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /popularizeMember/createPopularizeMember [post]

export var createPopularizeMember = function createPopularizeMember(data) {
  return service({
    url: "/popularizeMember/createPopularizeMember",
    method: 'post',
    data: data
  });
}; // @Tags PopularizeMember
// @Summary 删除PopularizeMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PopularizeMember true "删除PopularizeMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /popularizeMember/deletePopularizeMember [delete]

export var deletePopularizeMember = function deletePopularizeMember(data) {
  return service({
    url: "/popularizeMember/deletePopularizeMember",
    method: 'delete',
    data: data
  });
}; // @Tags PopularizeMember
// @Summary 删除PopularizeMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除PopularizeMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /popularizeMember/deletePopularizeMember [delete]

export var deletePopularizeMemberByIds = function deletePopularizeMemberByIds(data) {
  return service({
    url: "/popularizeMember/deletePopularizeMemberByIds",
    method: 'delete',
    data: data
  });
}; // @Tags PopularizeMember
// @Summary 更新PopularizeMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PopularizeMember true "更新PopularizeMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /popularizeMember/updatePopularizeMember [put]

export var updatePopularizeMember = function updatePopularizeMember(data) {
  return service({
    url: "/popularizeMember/updatePopularizeMember",
    method: 'put',
    data: data
  });
}; // @Tags PopularizeMember
// @Summary 用id查询PopularizeMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PopularizeMember true "用id查询PopularizeMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /popularizeMember/findPopularizeMember [get]

export var findPopularizeMember = function findPopularizeMember(params) {
  return service({
    url: "/popularizeMember/findPopularizeMember",
    method: 'get',
    params: params
  });
}; // @Tags PopularizeMember
// @Summary 分页获取PopularizeMember列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取PopularizeMember列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /popularizeMember/getPopularizeMemberList [get]

export var getPopularizeMemberList = function getPopularizeMemberList(params) {
  return service({
    url: "/popularizeMember/getPopularizeMemberList",
    method: 'get',
    params: params
  });
};